var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ConfigAll = void 0;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _Url = require("cqrs-shared/build/src/uri/Url");
var _ThemeManager = require("materialTheme/build/src/theme/ThemeManager");
var _SimpleStorage = require("odatarepos/build/src/db/SimpleStorage");
var _reactNativeWeb = require("../../../../upmesh-support/node_modules/react-native-web");
var _reactNativeDeviceInfo = _interopRequireDefault(require("react-native-device-info"));
var localUrl = 'http://127.0.0.1/api';
var localHostname = 'dev';
if (_reactNativeWeb.Platform.OS !== 'web' && __DEV__) {
  try {
    localUrl = require("./localUrl").default;
  } catch (e) {}
}
try {
  localHostname = require("./localHostname").default;
} catch (e) {}
var ConfigAll = exports.ConfigAll = function () {
  function ConfigAll() {
    (0, _classCallCheck2.default)(this, ConfigAll);
  }
  return (0, _createClass2.default)(ConfigAll, null, [{
    key: "SENTRY_CONFIG_KEY",
    get: function get() {
      return 'https://b1b7dd1e52d848728968e55b476ec864@s.upmesh.de/2';
    }
  }, {
    key: "SUPPORTED_IMAGE_MIME_TYPES",
    get: function get() {
      var imageMimeTypes = _reactNativeWeb.Platform.OS === 'ios' ? ['public.jpeg', 'public.png'] : ['image/jpeg', 'image/png'];
      return imageMimeTypes;
    }
  }, {
    key: "b2cURL",
    get: function get() {
      return ConfigAll._backendUrl;
    }
  }, {
    key: "MOBILE_VERSION_INFO",
    get: function get() {
      return `${_reactNativeDeviceInfo.default.getVersion()}@${_reactNativeDeviceInfo.default.getBuildNumber()}`;
    }
  }, {
    key: "WEB_VERSION_INFO",
    get: function get() {
      return '1.50.1@2025011801';
    }
  }, {
    key: "getVersion",
    value: function getVersion() {
      return _reactNativeWeb.Platform.OS === 'web' ? ConfigAll.WEB_VERSION_INFO : ConfigAll.MOBILE_VERSION_INFO;
    }
  }, {
    key: "getVersionCode",
    value: function getVersionCode() {
      var version = _reactNativeWeb.Platform.OS === 'web' ? ConfigAll.WEB_VERSION_INFO : ConfigAll.MOBILE_VERSION_INFO;
      return version.split('@')[1];
    }
  }, {
    key: "setBasename",
    value: function setBasename(url) {
      var uri = _Url.Url.getURLfromString(url);
      _ThemeManager.ThemeManager.style.basename = uri.hostname === 'dev.upmesh.de' ? '/app/' : '/';
    }
  }, {
    key: "getEnvironment",
    value: function getEnvironment() {
      if (ConfigAll._backendUrl === 'https://api.upmesh.de') return 'production';
      if (ConfigAll._backendUrl === 'https://dev.upmesh.de/api/upmesh') return 'testing';
      if (ConfigAll._backendUrl === 'https://devapi.upmesh.de') return 'testing';
      return 'development';
    }
  }, {
    key: "getHost",
    value: function getHost() {
      if (localHostname != null) return `${localHostname}`;
      return '';
    }
  }, {
    key: "init",
    value: function () {
      var _init = (0, _asyncToGenerator2.default)(function* () {
        if (_reactNativeWeb.Platform.OS === 'web') {
          var url = location.href;
          if (url.indexOf('devapp.upmesh.de') >= 0) {
            ConfigAll._backendUrl = 'https://devapi.upmesh.de';
            return;
          }
          if (url.indexOf('devcontrol.upmesh.de') >= 0) {
            ConfigAll._backendUrl = 'https://devapi.upmesh.de';
            return;
          }
          if (url.indexOf('app.upmesh.de') >= 0) {
            ConfigAll._backendUrl = 'https://api.upmesh.de';
            return;
          }
          if (url.indexOf('dev.upmesh.de') >= 0) {
            ConfigAll._backendUrl = 'https://dev.upmesh.de/api/upmesh';
            return;
          }
          if (url.indexOf('127.0.0.1:30666') >= 0) {
            ConfigAll._backendUrl = 'http://127.0.0.1:30012/api';
            return;
          }
          if (url.indexOf('127.0.0.1') >= 0 || url.indexOf('127.0.0.1') >= 0) {
            return;
          }
          if (url.indexOf('localhost') >= 0 || url.indexOf('localhost') >= 0) {
            return;
          }
          if (url.indexOf('control.upmesh.de') >= 0) {
            ConfigAll._backendUrl = 'https://api.upmesh.de';
            return;
          }
          if (url.indexOf('demo.upmesh.de') >= 0) {
            ConfigAll._backendUrl = 'https://demo.upmesh.de/api';
            return;
          }
          if (url.indexOf('pilot.upmesh.de') >= 0) {
            ConfigAll._backendUrl = 'https://pilotapi.upmesh.de';
            return;
          }
        }
        if (_reactNativeWeb.Platform.OS !== 'web' && __DEV__) {
          ConfigAll._backendUrl = localUrl;
          return;
        }
        try {
          var d;
          var data = yield fetch(`https://api.upmesh.de/backend/(${ConfigAll.getVersionCode()})`);
          if (data != null) {
            if (data.status === 404) {
              return;
            }
            d = yield data.json();
          }
          if (d != null && d['link'] != null) {
            ConfigAll._backendUrl = d['link'];
            ConfigAll.setBasename(d['link']);
            _SimpleStorage.SimpleStorage.set('lastBackendUrl', d['link']);
          } else {
            var getLink = _SimpleStorage.SimpleStorage.get('lastBackendUrl');
            if (getLink != null) {
              ConfigAll._backendUrl = getLink;
              ConfigAll.setBasename(getLink);
            }
          }
        } catch (e) {
          var _getLink = _SimpleStorage.SimpleStorage.get('lastBackendUrl');
          if (_getLink != null) {
            ConfigAll._backendUrl = _getLink;
            ConfigAll.setBasename(_getLink);
          }
        }
      });
      function init() {
        return _init.apply(this, arguments);
      }
      return init;
    }()
  }]);
}();
ConfigAll._backendUrl = 'http://127.0.0.1:30012/api';