var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ZoomTile = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _maplibreGl = _interopRequireDefault(require("!maplibre-gl"));
var _html2canvas = _interopRequireDefault(require("html2canvas"));
require("maplibre-gl/dist/maplibre-gl.css");
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactMapGl = _interopRequireWildcard(require("react-map-gl"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var uuid = _interopRequireWildcard(require("uuid"));
var _connectionContext = _interopRequireDefault(require("../../../connectionContext"));
var _I18n = require("../../i18n/I18n");
var _ResizeEvent = require("../../ResizeEvent");
var _LoadingEvents = require("../../routing/LoadingEvents");
var _ThemeManager = require("../../ThemeManager");
var _Icon = require("../Icon");
var _Spinner = require("../Spinner");
var _MaterialText = require("../text/MaterialText");
var _ClusterLayer = require("./ClusterLayer");
var _MapMarker = require("./MapMarker");
var _ZoomTileHelper = require("./ZoomTileHelper");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ZoomTile = exports.ZoomTile = function (_PureComponent) {
  function ZoomTile(props) {
    var _this;
    (0, _classCallCheck2.default)(this, ZoomTile);
    _this = _callSuper(this, ZoomTile, [props]);
    _this.label = uuid.v1();
    _this.mouseDown = false;
    _this.mousedown = function (e) {
      _this.mouseDown = true;
      var _this$props = _this.props,
        type = _this$props.type,
        info = _this$props.info;
      _this.mapTapHoldTimeout = setTimeout(function () {
        e.originalEvent.preventDefault();
        e.originalEvent.persist();
        if (e['lngLat'] != null && info != null) {
          var point = type === 'map' ? {
            x: e['lngLat'].lng,
            y: e['lngLat'].lat
          } : _ZoomTileHelper.ZoomTileHelper.latLngToPoint(e['lngLat'], info);
          _this.onLongPress(Object.assign({}, point, {
            nativeEvent: e['originalEvent']
          }));
        }
      }, 500);
    };
    _this.mouseup = function (_e) {
      _this.mouseDown = false;
      if (_this.mapTapHoldTimeout) clearTimeout(_this.mapTapHoldTimeout);
    };
    _this.mousemove = function (e) {
      if (_this.mapTapHoldTimeout) clearTimeout(_this.mapTapHoldTimeout);
      if (_this.mouseDown) return;
      if (e.features != null && e.features.length > 0) {
        var feature = e.features[0];
        var showCalloutForId = _this.state.showCalloutForId;
        if (feature.properties.id != null && showCalloutForId !== feature.properties.id) {
          try {
            _this.setState({
              showCalloutForId: feature.properties.id
            });
          } catch (err) {}
        } else if (showCalloutForId != null) _this.setState({
          showCalloutForId: showCalloutForId
        });
      }
    };
    _this.onClick = function (e) {
      if (_this.longPressed) {
        _this.longPressed = false;
        return;
      }
      var _this$props2 = _this.props,
        onPress = _this$props2.onPress,
        type = _this$props2.type,
        info = _this$props2.info;
      if (onPress != null && e['lngLat'] != null && info != null) {
        var point = type === 'map' ? {
          x: e['lngLat'].lng,
          y: e['lngLat'].lat
        } : _ZoomTileHelper.ZoomTileHelper.latLngToPoint(e['lngLat'], info);
        onPress(Object.assign({}, point, {
          nativeEvent: e['originalEvent']
        }));
      }
      if (e.features != null && e.features.length > 0) {
        var feature = e.features[0];
        if (feature.properties.cluster_id != null) {
          var clusterId = feature.properties.cluster_id;
          var mapboxSource = _this.map.getSource('earthquakes');
          try {
            mapboxSource.getClusterExpansionZoom(clusterId).then(function (zoom) {
              _this.map.easeTo({
                center: feature.geometry.coordinates,
                zoom: zoom,
                duration: 500
              });
            }).catch(function (err) {});
          } catch (e) {}
        } else if (feature.properties.id != null) {
          try {
            var marker = _this.state.marker;
            var m = marker == null ? void 0 : marker.find(function (m) {
              return m.id === feature.properties.id;
            });
            if (m != null) {
              if (m.onClick != null) m.onClick(e);else _this.onCalloutPress(m)(e);
            }
          } catch (err) {}
        }
      }
    };
    _this.longPressed = false;
    _this.onLongPress = function (e) {
      _this.longPressed = true;
      var onLongPress = _this.props.onLongPress;
      if (onLongPress != null) onLongPress(e);
    };
    _this.onViewChange = function (e) {
      var _this$props3 = _this.props,
        onRegionChange = _this$props3.onRegionChange,
        type = _this$props3.type,
        info = _this$props3.info;
      if (onRegionChange != null && info != null && e != null && e.viewState != null) {
        var latlng = {
          lat: e.viewState.latitude,
          lng: e.viewState.longitude
        };
        var point = type === 'map' ? {
          x: latlng.lng,
          y: latlng.lat
        } : _ZoomTileHelper.ZoomTileHelper.latLngToPoint(latlng, info);
        var r = {
          center: {
            latlng: latlng,
            point: point
          },
          zoom: e.viewState.zoom
        };
        onRegionChange(r);
      }
    };
    _this.onCalloutPress = function (marker) {
      return function (e) {
        var onCalloutPress = _this.props.onCalloutPress;
        if (onCalloutPress != null) {
          onCalloutPress(Object.assign({}, marker, {
            nativeEvent: e['originalEvent']
          }));
        }
      };
    };
    _this.onDrag = function (m) {
      return function (e) {
        if (m.onDragged != null) {
          var info = _this.props.info;
          if (info != null && e.target != null && e.target['_lngLat'] != null && e.target['_lngLat']['lat'] != null && e.target['_lngLat']['lng'] != null) {
            var _ZoomTileHelper$latLn = _ZoomTileHelper.ZoomTileHelper.latLngToPoint(Object.assign({}, e.target['_lngLat']), info),
              x = _ZoomTileHelper$latLn.x,
              y = _ZoomTileHelper$latLn.y;
            m.onDragged({
              x: x,
              y: y
            });
          }
        }
      };
    };
    _this.setMapRef = function (m) {
      _this.map = m;
      if (_this.map != null) {
        _this.loadIDefaultmagesToMap().then(function () {
          if (_this.zoomToAfterRef != null) {
            var _this2 = _this,
              zoomToAfterRef = _this2.zoomToAfterRef;
            _this.zoomToAfterRef = null;
            requestAnimationFrame(function () {
              _this.zoomTo(zoomToAfterRef == null ? void 0 : zoomToAfterRef.point, zoomToAfterRef == null ? void 0 : zoomToAfterRef.zoomLevel);
            });
          }
          _this.forceUpdate();
        }).catch(function (err) {
          return void 0;
        });
      }
    };
    _this.zoomToAfterRef = null;
    _this.onLayout = function (_e) {
      if (_this.map != null) {
        _this.map.resize();
      }
    };
    _this.makeScreenshot = function (_e, callback) {
      var onS = _this.props.onSnapShot;
      var onSnapShot = callback != null ? callback : onS;
      var el = document.querySelector(`[aria-label="${_this.label}"]`);
      if (el != null && onSnapShot != null) {
        _LoadingEvents.LoadingEvents.instance.startLoading();
        _this.setState({
          hideControls: true
        }, function () {
          setTimeout(function () {
            (0, _html2canvas.default)(el).then(function (canvas) {
              canvas.toBlob(function (blob) {
                if (blob != null) {
                  blob['name'] = `snapshot_${_I18n.I18n.m.dateCurrent.localeDateWithTimeString(new Date())}.png`;
                  onSnapShot(blob);
                }
                _LoadingEvents.LoadingEvents.instance.stopLoading();
                _this.setState({
                  hideControls: false
                });
              }, 'image/png');
            }).catch(function (err) {
              _LoadingEvents.LoadingEvents.instance.stopLoading();
              _this.setState({
                hideControls: false
              });
            });
          }, 300);
        });
      }
    };
    _this.state = {
      hideControls: false
    };
    return _this;
  }
  (0, _inherits2.default)(ZoomTile, _PureComponent);
  return (0, _createClass2.default)(ZoomTile, [{
    key: "renderMarker",
    value: function renderMarker() {
      var _this3 = this;
      var marker = this.state.marker;
      if (marker == null || marker.length === 0) {
        return null;
      }
      var _this$props4 = this.props,
        type = _this$props4.type,
        info = _this$props4.info;
      var showCalloutForId = this.state.showCalloutForId;
      var ms = [];
      if (info != null) {
        var _loop = function _loop(m) {
          var calculated = type === 'map' ? {
            lat: Math.max(-90, Math.min(90, m.position.y)),
            lng: Math.max(-180, Math.min(180, m.position.x))
          } : _ZoomTileHelper.ZoomTileHelper.pointToLatLng(m.position, info);
          var mProps = {};
          var tooltip = null;
          if (m.tooltip != null && showCalloutForId === m.id) {
            var tId = `popop_${m.id}`;
            tooltip = (0, _jsxRuntime.jsx)(_reactMapGl.Popup, {
              style: {
                visibility: showCalloutForId === m.id ? 'visible' : 'hidden',
                padding: 0
              },
              longitude: calculated.lng,
              latitude: calculated.lat,
              anchor: "left",
              offset: [24, -36],
              closeButton: false,
              children: m.tooltip
            }, tId);
          }
          if (m.tooltip != null) {
            mProps = {
              onMouseEnter: function onMouseEnter(_e) {
                _this3.setState({
                  showCalloutForId: m.id
                });
              },
              onMouseLeave: function onMouseLeave(_e) {
                _this3.setState({
                  showCalloutForId: undefined
                });
              }
            };
          }
          if (m.dragable) {
            ms.push((0, _jsxRuntime.jsx)(_reactMapGl.Marker, {
              onClick: m.onClick == null ? _this3.onCalloutPress(m) : m.onClick,
              onDragEnd: m.onDragged != null ? _this3.onDrag(m) : undefined,
              longitude: calculated.lng,
              latitude: calculated.lat,
              anchor: "bottom",
              draggable: m.dragable,
              children: (0, _jsxRuntime.jsx)("img", Object.assign({
                alt: m.color,
                src: _MapMarker.MapMarker.getB64Svg(48, m.color, m.fillColor),
                style: {
                  width: 48,
                  height: 48
                }
              }, mProps))
            }, `marker_${m.id}`));
          }
          if (tooltip != null) {
            ms.push(tooltip);
          }
        };
        for (var m of marker) {
          _loop(m);
        }
      }
      return ms;
    }
  }, {
    key: "loadImageToMap",
    value: function () {
      var _loadImageToMap = (0, _asyncToGenerator2.default)(function* (key) {
        var _this4 = this;
        var c = key.split('#');
        if (c.length > 0) {
          var preChar = key.startsWith('#') ? '#' : '';
          var color = `${preChar}${c[0]}`;
          var fillColor;
          if (c.length > 1) {
            color = `${preChar}${c[1]}`;
            if (c.length > 2) fillColor = `${preChar}${c[2]}`;
          }
          var i = _MapMarker.MapMarker.getB64Svg(48, color, fillColor);
          yield new Promise(function (r) {
            if (_this4.map != null && !_this4.map.hasImage(key)) {
              var img = new Image(31, 48);
              img.onload = function () {
                if (_this4.map != null && !_this4.map.hasImage(key)) {
                  _this4.map.addImage(key, img, {
                    sdf: false,
                    pixelRatio: 1
                  });
                }
                r();
              };
              img.src = i;
            }
          });
        }
      });
      function loadImageToMap(_x) {
        return _loadImageToMap.apply(this, arguments);
      }
      return loadImageToMap;
    }()
  }, {
    key: "loadIDefaultmagesToMap",
    value: function () {
      var _loadIDefaultmagesToMap = (0, _asyncToGenerator2.default)(function* () {
        for (var key in (0, _MapMarker.mapMarkers)()) {
          yield this.loadImageToMap(key);
        }
      });
      function loadIDefaultmagesToMap() {
        return _loadIDefaultmagesToMap.apply(this, arguments);
      }
      return loadIDefaultmagesToMap;
    }()
  }, {
    key: "zoomTo",
    value: function zoomTo(point) {
      var _this5 = this;
      var zoomLevel = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 4;
      var selectedId = arguments.length > 2 ? arguments[2] : undefined;
      var _this$props5 = this.props,
        type = _this$props5.type,
        info = _this$props5.info;
      if (this.map != null && info != null) {
        var lngLat = type === 'map' ? {
          lat: point.y,
          lng: point.x
        } : _ZoomTileHelper.ZoomTileHelper.pointToLatLng(point, info);
        this.map.easeTo({
          zoom: zoomLevel,
          center: lngLat
        });
        if (selectedId != null) setTimeout(function () {
          return _this5.setState({
            showCalloutForId: selectedId
          });
        }, 400);
      } else this.zoomToAfterRef = {
        point: point,
        zoomLevel: zoomLevel
      };
    }
  }, {
    key: "takeSnapshot",
    value: function () {
      var _takeSnapshot = (0, _asyncToGenerator2.default)(function* () {
        var _this6 = this;
        return new Promise(function (resolve, reject) {
          try {
            _this6.makeScreenshot(null, function (f) {
              resolve(f);
            });
          } catch (e) {
            reject(e);
          }
        });
      });
      function takeSnapshot() {
        return _takeSnapshot.apply(this, arguments);
      }
      return takeSnapshot;
    }()
  }, {
    key: "componentDidUpdate",
    value: function componentDidUpdate(_prevProps, _prevState) {
      var _this7 = this;
      var data = this.state.data;
      if (this.map != null && data != null && data.features != null && data.features.length > 0) {
        var _loop2 = function _loop2() {
          var _m$properties, _m$properties2, _m$properties3;
          var key = `${(_m$properties = m.properties) == null ? void 0 : _m$properties.color}${(_m$properties2 = m.properties) != null && _m$properties2.fillcolor ? (_m$properties3 = m.properties) == null ? void 0 : _m$properties3.fillcolor : ''}`;
          if (key != null && !_this7.map.hasImage(key)) {
            _this7.loadImageToMap(key).catch(function (err) {
              return void 0;
            });
          }
        };
        for (var m of data.features) {
          _loop2();
        }
      }
    }
  }, {
    key: "render",
    value: function render() {
      var _this$state = this.state,
        hideControls = _this$state.hideControls,
        data = _this$state.data;
      var _this$props6 = this.props,
        type = _this$props6.type,
        url = _this$props6.url,
        initialView = _this$props6.initialView,
        onSnapShot = _this$props6.onSnapShot,
        info = _this$props6.info,
        basicAuth = _this$props6.basicAuth,
        infoText = _this$props6.infoText;
      var connectedToServer = this.context.connectedToServer;
      if (!connectedToServer) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            justifyContent: 'center',
            flexDirection: 'row',
            alignItems: 'center',
            alignContent: 'center'
          },
          children: (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: {
              alignSelf: 'center'
            },
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              centeredBox: true,
              centeredText: true,
              children: _I18n.I18n.m.getMessage('planOfflineNotAvailable')
            })
          })
        });
      }
      if (url == null || info == null) {
        return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: {
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center'
          },
          children: (0, _jsxRuntime.jsx)(_Spinner.Spinner, {})
        });
      }
      var _this$props7 = this.props,
        zoomAble = _this$props7.zoomAble,
        clusteringDisabled = _this$props7.clusteringDisabled,
        templateFormat = _this$props7.templateFormat;
      var headers = {};
      headers['authorization'] = `Basic ${basicAuth}`;
      var initialViewState;
      if (initialView != null && info != null) {
        var latlng = type === 'map' ? {
          lng: initialView.point.x,
          lat: initialView.point.y
        } : _ZoomTileHelper.ZoomTileHelper.pointToLatLng(initialView.point, info);
        initialViewState = {
          longitude: latlng.lng,
          latitude: latlng.lat,
          zoom: initialView.zoom
        };
      }
      var rasterSource = {
        type: 'raster',
        tiles: type === 'plan' ? templateFormat == null ? [`${url}/{z}/{x}/{y}/`] : [`${url}/{z}/{x}_{y}${templateFormat}`] : ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png', 'https://b.tile.openstreetmap.org/{z}/{x}/{y}.png', 'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png'],
        tileSize: 256,
        maxzoom: info.maxLevel
      };
      var rasterLayer = {
        id: 'test',
        type: 'raster',
        source: 'raster-tiles',
        minzoom: 0
      };
      var d = data == null || hideControls ? {
        type: 'FeatureCollection',
        features: []
      } : data;
      var sources = {
        'raster-tiles': rasterSource
      };
      sources['earthquakes'] = {
        type: 'geojson',
        data: d,
        cluster: clusteringDisabled !== true,
        clusterMaxZoom: info.maxLevel - 2,
        clusterRadius: 30,
        clusterMinPoints: 2
      };
      var mStyle = {
        version: 8,
        sources: sources,
        layers: [rasterLayer, _ClusterLayer.clusterLayer, _ClusterLayer.clusterCountLayer, (0, _ClusterLayer.unclusteredPointLayer)(1)],
        glyphs: `${window.location.protocol}//${window.location.host}/fonts/{fontstack}/{range}.pbf`
      };
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        collapsable: false,
        style: {
          width: '100%',
          height: '100%'
        },
        onLayout: this.onLayout,
        accessibilityLabel: this.label,
        children: (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
          collapsable: false,
          style: {
            position: 'relative',
            width: '100%',
            height: '100%'
          },
          onLayout: this.onLayout,
          children: [(0, _jsxRuntime.jsxs)(_reactMapGl.default, {
            ref: this.setMapRef,
            transformRequest: function transformRequest(url) {
              if (basicAuth != null) {
                return {
                  url: url,
                  headers: headers
                };
              }
              return {
                url: url
              };
            },
            preserveDrawingBuffer: true,
            attributionControl: false,
            maxParallelImageRequests: 8,
            mapLib: _maplibreGl.default,
            mapStyle: mStyle,
            onClick: this.onClick,
            onMouseDown: this.mousedown,
            onMouseMove: this.mousemove,
            onDrag: this.mousemove,
            onMouseUp: this.mouseup,
            initialViewState: initialViewState,
            scrollZoom: zoomAble === true,
            touchPitch: zoomAble === true,
            boxZoom: zoomAble === true,
            doubleClickZoom: zoomAble === true,
            touchZoomRotate: zoomAble === true,
            dragRotate: zoomAble === true,
            dragPan: zoomAble === true,
            pitchWithRotate: zoomAble === true,
            maxZoom: Math.max(6, info.maxLevel + 2),
            minZoom: -2,
            padding: {
              top: _ResizeEvent.ResizeEvent.current.windowHeight / 2,
              left: _ResizeEvent.ResizeEvent.current.windowWidth / 2,
              right: _ResizeEvent.ResizeEvent.current.windowWidth / 2,
              bottom: _ResizeEvent.ResizeEvent.current.windowHeight / 2
            },
            style: {
              width: '100%',
              height: '100%'
            },
            onBoxZoomEnd: this.onViewChange,
            onMoveEnd: this.onViewChange,
            renderWorldCopies: false,
            interactiveLayerIds: [_ClusterLayer.clusterLayer.id, (0, _ClusterLayer.unclusteredPointLayer)().id],
            children: [!zoomAble || hideControls ? null : (0, _jsxRuntime.jsx)(_reactMapGl.NavigationControl, {
              position: "top-right",
              showZoom: false,
              showCompass: true,
              visualizePitch: true
            }), this.renderMarker()]
          }), onSnapShot == null || hideControls ? null : (0, _jsxRuntime.jsx)("div", {
            className: "mapboxgl-ctrl mapboxgl-ctrl-group",
            style: {
              position: 'absolute',
              top: 56,
              right: 10
            },
            children: (0, _jsxRuntime.jsx)(_Icon.Icon, {
              iconSize: 29,
              outerSize: 29,
              icon: "image-area",
              toolTip: _I18n.I18n.m.getMessage('takeSnapshot'),
              onPress: this.makeScreenshot
            })
          }), infoText != null && infoText.length > 0 ? (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
            style: Object.assign({
              position: 'absolute',
              top: 4,
              left: 4,
              marginRight: 64,
              backgroundColor: 'rgba(255, 255, 255, 0.5)'
            }, _ThemeManager.ThemeManager.style.borderStyle),
            children: (0, _jsxRuntime.jsx)(_MaterialText.MaterialText, {
              children: infoText
            })
          }) : null]
        })
      });
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.marker == null) {
        return {
          marker: undefined,
          data: {
            type: 'FeatureCollection',
            features: []
          }
        };
      }
      if (prevState.marker == null || nextProps.marker !== prevState.marker) {
        return {
          marker: (0, _toConsumableArray2.default)(nextProps.marker),
          data: ZoomTile.generateData(nextProps)
        };
      }
      return null;
    }
  }, {
    key: "generateData",
    value: function generateData(props) {
      var type = props.type,
        info = props.info,
        marker = props.marker;
      var data = {
        type: 'FeatureCollection',
        features: []
      };
      if (marker != null && info != null) {
        var features = [];
        marker.forEach(function (m) {
          if (!m.dragable) {
            var calculated = type === 'map' ? {
              lat: Math.max(-90, Math.min(90, m.position.y)),
              lng: Math.max(-180, Math.min(180, m.position.x))
            } : _ZoomTileHelper.ZoomTileHelper.pointToLatLng(m.position, info);
            features.push({
              type: 'Feature',
              id: m.id,
              properties: {
                id: m.id,
                color: `${m.color}${m.fillColor != null ? m.fillColor : ''}`
              },
              geometry: {
                type: 'Point',
                coordinates: [calculated.lng, calculated.lat]
              }
            });
          }
        });
        data.features = [].concat(features);
      }
      return data;
    }
  }]);
}(_react.PureComponent);
ZoomTile.contextType = _connectionContext.default;