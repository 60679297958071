var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ContentHeader = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _ContentHeaderEventHandler = require("./ContentHeaderEventHandler");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ContentHeader = exports.ContentHeader = function (_PureComponent) {
  function ContentHeader(props) {
    var _this;
    (0, _classCallCheck2.default)(this, ContentHeader);
    _this = _callSuper(this, ContentHeader, [props]);
    _this.animatedTop = new _reactNativeWeb.Animated.Value(-_ThemeManager.ThemeManager.style.headerHeight - _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(10));
    _this.onLayout = function (_e) {
      _this.forceUpdate();
    };
    _this.open = false;
    _this.statusChange = function (status) {
      if (status.open) {
        _this.open = true;
        if (status.headerContent != null) {
          var headerHeight = status.headerHeight != null ? status.headerHeight : _ThemeManager.ThemeManager.style.headerHeight;
          _this.setState({
            headerHeight: headerHeight,
            headerContent: status.headerContent
          }, function () {
            _this.show();
          });
        }
      } else {
        _this.open = false;
        _this.hide();
      }
    };
    _this.state = {
      headerContent: null,
      headerHeight: _ThemeManager.ThemeManager.style.headerHeight
    };
    return _this;
  }
  (0, _inherits2.default)(ContentHeader, _PureComponent);
  return (0, _createClass2.default)(ContentHeader, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      _ContentHeaderEventHandler.ContentHeaderEventHandler.statusEvent.attach(this.statusChange);
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      _ContentHeaderEventHandler.ContentHeaderEventHandler.statusEvent.detach(this.statusChange);
    }
  }, {
    key: "render",
    value: function render() {
      var size = this.props.size;
      var _this$state = this.state,
        headerHeight = _this$state.headerHeight,
        headerContent = _this$state.headerContent;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.Animated.View, {
        onLayout: this.onLayout,
        style: Object.assign({}, _ThemeManager.ThemeManager.style.elevation4, {
          position: 'absolute',
          left: size.contentLeft,
          right: size.contentRight + size.sidebarWidth,
          top: 0,
          padding: 0,
          alignItems: 'flex-start',
          height: headerHeight,
          overflow: 'hidden',
          backgroundColor: _ThemeManager.ThemeManager.style.headerBG,
          justifyContent: 'flex-start',
          flexDirection: 'row',
          transform: [{
            translateY: this.animatedTop
          }]
        }),
        children: headerContent
      });
    }
  }, {
    key: "hide",
    value: function hide() {
      var _this2 = this;
      var headerHeight = this.state.headerHeight;
      _reactNativeWeb.Animated.timing(this.animatedTop, {
        duration: 195,
        useNativeDriver: _reactNativeWeb.Platform.OS !== 'web',
        toValue: -headerHeight - _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(10)
      }).start(function () {
        _this2.setState({
          headerContent: null
        });
      });
    }
  }, {
    key: "show",
    value: function show() {
      var size = this.props.size;
      _reactNativeWeb.Animated.timing(this.animatedTop, {
        duration: 195,
        useNativeDriver: _reactNativeWeb.Platform.OS !== 'web',
        toValue: size.contentTop
      }).start();
    }
  }]);
}(_react.PureComponent);