var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StatusBarBackground = StatusBarBackground;
exports.StatusBarBackgroundChangeEvent = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _tsEvents = require("ts-events");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var StatusBarBackgroundChangeEvent = exports.StatusBarBackgroundChangeEvent = new _tsEvents.AsyncEvent();
function StatusBarBackground(props) {
  var _useState = (0, _react.useState)('#FFFFFF'),
    _useState2 = (0, _slicedToArray2.default)(_useState, 2),
    color = _useState2[0],
    setColor = _useState2[1];
  var changeColor = function changeColor(event) {
    if (event.color === '#000000' && _reactNativeWeb.StatusBar != null && typeof _reactNativeWeb.StatusBar.setBarStyle === 'function') _reactNativeWeb.StatusBar.setBarStyle('light-content', true);else if (event.color === '#FFFFFF' && _reactNativeWeb.StatusBar != null && typeof _reactNativeWeb.StatusBar.setBarStyle === 'function') _reactNativeWeb.StatusBar.setBarStyle('dark-content', true);
    setColor(event.color);
  };
  (0, _react.useEffect)(function () {
    StatusBarBackgroundChangeEvent.attach(changeColor);
    return function () {
      StatusBarBackgroundChangeEvent.detach(changeColor);
    };
  });
  return (0, _jsxRuntime.jsxs)(_reactNativeWeb.View, {
    style: _reactNativeWeb.StyleSheet.absoluteFill,
    children: [(0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: props.size.contentTop,
        backgroundColor: color
      }
    }), (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
      style: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: props.size.contentBottom,
        backgroundColor: color
      }
    })]
  });
}