var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GridList = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../upmesh-support/node_modules/react-native-web");
var _VirtualGridList = _interopRequireDefault(require("./List/VirtualGridList"));
var _ListItem = require("./ListItem");
var _KeyboardEvents = require("./utils/KeyboardEvents");
var _jsxRuntime = require("../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var GridList = exports.GridList = function (_PureComponent) {
  function GridList(props) {
    var _this;
    (0, _classCallCheck2.default)(this, GridList);
    _this = _callSuper(this, GridList, [props]);
    _this.setRef = function (ref) {
      _this.ref = ref;
    };
    _this.startKeyboardEvents = function () {
      var _this$props = _this.props,
        selectable = _this$props.selectable,
        numColumns = _this$props.numColumns,
        direction = _this$props.direction;
      var listData = _this.state.listData;
      if (selectable !== true) {
        return;
      }
      _KeyboardEvents.KeyboardEvents.onKeyDown(function (e) {
        var isFocused = true;
        if (isFocused === true && listData != null && listData.length > 0) {
          var selectedIndex = _this.state.selectedIndex;
          var sideMulti = direction === 'horizontal' && numColumns != null && numColumns > 0 ? numColumns : 1;
          var skyMulti = direction === 'horizontal' ? 1 : numColumns != null && numColumns > 0 ? numColumns : 1;
          var newIndex = selectedIndex;
          switch (e.keyCode) {
            case 13:
              if (selectedIndex > -1) {
                e.preventDefault();
                _this.onSubmit(selectedIndex);
              }
              break;
            case 37:
              e.preventDefault();
              newIndex = selectedIndex - sideMulti < 0 ? listData.length - sideMulti : selectedIndex - sideMulti;
              break;
            case 38:
              e.preventDefault();
              newIndex = selectedIndex - skyMulti < 0 ? listData.length - skyMulti : selectedIndex - skyMulti;
              break;
            case 39:
              e.preventDefault();
              newIndex = selectedIndex >= listData.length - sideMulti ? 0 : selectedIndex + sideMulti;
              break;
            case 40:
              e.preventDefault();
              newIndex = selectedIndex >= listData.length - skyMulti ? 0 : selectedIndex + skyMulti;
              break;
          }
          if (newIndex !== selectedIndex) {
            _this.setState({
              selectedIndex: newIndex
            }, function () {
              _this.onSelect(newIndex);
              if (_this.ref && typeof _this.ref.scrollToIndex === 'function') {
                _this.ref.scrollToIndex(newIndex);
              }
            });
          }
        }
      }, 'List');
    };
    _this.stopKeyboardEvents = function () {
      _KeyboardEvents.KeyboardEvents.remove('List');
    };
    _this.onClick = function (selectedIndex) {
      return function (_e) {
        var selectable = _this.props.selectable;
        if (selectable === true) {
          _this.onSelect(selectedIndex);
        }
        _this.onSubmit(selectedIndex);
      };
    };
    _this.onLayout = function (e) {
      var _this$state = _this.state,
        width = _this$state.width,
        height = _this$state.height;
      if (e != null && e.nativeEvent != null && e.nativeEvent.layout != null && e.nativeEvent.layout.width != null && e.nativeEvent.layout.height != null && (e.nativeEvent.layout.width !== width || e.nativeEvent.layout.height !== height)) {
        _this.setState({
          width: e.nativeEvent.layout.width,
          height: e.nativeEvent.layout.height
        }, function () {});
      }
    };
    _this.onSelect = function (selectedIndex) {
      if (_this.props.selectable === true) {
        _this.setState({
          selectedIndex: selectedIndex
        }, function () {
          if (_this.props.onChangeSelected != null) {
            _this.props.onChangeSelected(_this.state.listData[selectedIndex], selectedIndex);
          }
          if (_this.ref) {
            _this.ref.forceUpdate();
          }
        });
      }
    };
    _this.onSubmit = function (selectedIndex) {
      var listData = _this.props.listData;
      if (listData != null && listData.length > selectedIndex && listData[selectedIndex].onPressAll != null) {
        listData[selectedIndex].onPressAll();
      }
      if (_this.props.onSubmit != null) {
        _this.props.onSubmit(_this.state.listData[selectedIndex], selectedIndex);
      }
    };
    _this.renderItem = function (_ref) {
      var index = _ref.index,
        style = _ref.style;
      var renderRow = _this.props.renderRow;
      var _this$state2 = _this.state,
        listData = _this$state2.listData,
        selectedIndex = _this$state2.selectedIndex;
      if (listData == null || index > listData.length) {
        return null;
      }
      var item = listData[index];
      var selected = selectedIndex === index;
      var listItem = renderRow != null ? renderRow({
        item: item,
        index: index,
        selectedIndex: selectedIndex
      }) : (0, _jsxRuntime.jsx)(_ListItem.ListItem, Object.assign({}, item, {
        selected: selected,
        onPress: _this.onClick(index)
      }));
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: style,
        children: listItem
      }, index);
    };
    var listData = props.listData;
    _this.state = {
      listData: listData,
      width: 1,
      height: 1,
      selectedIndex: props.selectedIndex
    };
    if (listData != null && listData.length > 0) {
      _this.startKeyboardEvents();
    }
    return _this;
  }
  (0, _inherits2.default)(GridList, _PureComponent);
  return (0, _createClass2.default)(GridList, [{
    key: "componentDidMount",
    value: function componentDidMount() {
      if (this.state.listData != null && this.state.listData.length > 0) {
        this.startKeyboardEvents();
      }
    }
  }, {
    key: "componentWillUnmount",
    value: function componentWillUnmount() {
      this.stopKeyboardEvents();
    }
  }, {
    key: "render",
    value: function render() {
      var overflowStyle = _reactNativeWeb.Platform.OS === 'web' ? {
        overflow: 'auto'
      } : {
        overflow: 'scroll'
      };
      var _this$props2 = this.props,
        scrollOffset = _this$props2.scrollOffset,
        itemSize = _this$props2.itemSize,
        numColumns = _this$props2.numColumns,
        direction = _this$props2.direction,
        style = _this$props2.style,
        align = _this$props2.align,
        scrollBarVisible = _this$props2.scrollBarVisible;
      var _this$state3 = this.state,
        height = _this$state3.height,
        width = _this$state3.width,
        listData = _this$state3.listData;
      var virtualList = width > 0 && height > 0 && listData.length > 0 ? (0, _jsxRuntime.jsx)(_VirtualGridList.default, {
        scrollOffset: scrollOffset,
        scrollDirection: direction,
        numColumns: numColumns != null && numColumns > 0 ? numColumns : 1,
        scrollToIndex: this.props.scrollToIndex,
        ref: this.setRef,
        width: width,
        height: height,
        itemCount: listData.length,
        itemSize: itemSize,
        renderItem: this.renderItem,
        align: align != null ? align : 'flex-start',
        style: overflowStyle,
        showsVerticalScrollIndicator: scrollBarVisible
      }, `vl_${listData.length}`) : null;
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        onLayout: this.onLayout,
        style: style,
        children: virtualList
      });
    }
  }], [{
    key: "defaultProps",
    get: function get() {
      return {
        id: 'list',
        numColumns: 1,
        selectedIndex: -1,
        itemSize: {
          width: 100,
          height: 100
        },
        direction: 'vertical',
        align: 'flex-start'
      };
    }
  }, {
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.listData !== prevState.listData) {
        var listData = nextProps.listData != null ? nextProps.listData : [];
        return {
          listData: listData,
          selectedIndex: nextProps.selectedIndex != null ? nextProps.selectedIndex : 0
        };
      }
      return null;
    }
  }]);
}(_react.PureComponent);