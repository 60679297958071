var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ButtonGroup = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _possibleConstructorReturn2 = _interopRequireDefault(require("@babel/runtime/helpers/possibleConstructorReturn"));
var _getPrototypeOf2 = _interopRequireDefault(require("@babel/runtime/helpers/getPrototypeOf"));
var _inherits2 = _interopRequireDefault(require("@babel/runtime/helpers/inherits"));
var _react = _interopRequireWildcard(require("../../../../../../upmesh-support/node_modules/react"));
var _reactNativeWeb = require("../../../../../../upmesh-support/node_modules/react-native-web");
var _ThemeManager = require("../../ThemeManager");
var _jsxRuntime = require("../../../../../../upmesh-support/node_modules/react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function _callSuper(t, o, e) { return o = (0, _getPrototypeOf2.default)(o), (0, _possibleConstructorReturn2.default)(t, _isNativeReflectConstruct() ? Reflect.construct(o, e || [], (0, _getPrototypeOf2.default)(t).constructor) : o.apply(t, e)); }
function _isNativeReflectConstruct() { try { var t = !Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); } catch (t) {} return (_isNativeReflectConstruct = function _isNativeReflectConstruct() { return !!t; })(); }
var ButtonGroup = exports.ButtonGroup = function (_PureComponent) {
  function ButtonGroup(props) {
    var _this;
    (0, _classCallCheck2.default)(this, ButtonGroup);
    _this = _callSuper(this, ButtonGroup, [props]);
    _this.state = {
      childs: []
    };
    return _this;
  }
  (0, _inherits2.default)(ButtonGroup, _PureComponent);
  return (0, _createClass2.default)(ButtonGroup, [{
    key: "render",
    value: function render() {
      var childs = this.state.childs;
      var _this$props = this.props,
        direction = _this$props.direction,
        align = _this$props.align,
        alignSelf = _this$props.alignSelf,
        marginLeft = _this$props.marginLeft,
        marginRight = _this$props.marginRight,
        marginBottom = _this$props.marginBottom,
        marginTop = _this$props.marginTop;
      if (childs == null) {
        return null;
      }
      return (0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
        style: {
          marginLeft: marginLeft,
          marginRight: marginRight,
          marginBottom: marginBottom,
          marginTop: marginTop,
          height: 'auto',
          flexDirection: direction === 'vertical' ? 'column' : 'row',
          width: direction === 'vertical' ? 'auto' : '100%',
          flexWrap: direction === 'vertical' ? undefined : 'wrap',
          justifyContent: align != null ? align : 'flex-start',
          alignContent: align != null ? align : 'flex-start',
          alignSelf: alignSelf != null ? alignSelf : 'baseline',
          bottom: 0
        },
        children: childs
      });
    }
  }], [{
    key: "getDerivedStateFromProps",
    value: function getDerivedStateFromProps(nextProps, prevState) {
      var padding = nextProps.buttonMargin == null ? _ThemeManager.ThemeManager.style.getScreenRelativePixelSize(8) : nextProps.buttonMargin;
      var nextChildren = nextProps.children;
      if (!Array.isArray(nextChildren)) {
        nextChildren = [nextChildren];
      }
      return Object.assign({}, prevState, {
        childs: ButtonGroup.createChilds(nextChildren, padding, nextProps.direction)
      });
    }
  }, {
    key: "createChilds",
    value: function createChilds(children, margin, direction) {
      if (children == null || children.length === 0) {
        return [];
      }
      var childs = [];
      for (var i = 0; i < children.length; i += 1) {
        var style = {};
        if (direction !== 'vertical') {
          style = {
            marginLeft: 0
          };
        }
        childs.push((0, _jsxRuntime.jsx)(_reactNativeWeb.View, {
          style: Object.assign({
            margin: margin
          }, style),
          children: children[i]
        }, `buttonGroup${i}`));
      }
      return childs;
    }
  }]);
}(_react.PureComponent);